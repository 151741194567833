(function () {
    window.CINEPLEX.ImageLazyLoader = {};
    CINEPLEX.ImageLazyLoader.GetLazyElements = function (parentSelector) {
        var lazyImages = [].slice.call(document.querySelectorAll(parentSelector + " source[data-srcset]"));
        lazyImages = lazyImages.concat([].slice.call(document.querySelectorAll(parentSelector + " img[data-src]")));
        return lazyImages;
    }

    var defaultImageSrc = "default-image";
    var defaultTransparentImageSrc = "default-transparent-image";
    var transparentLazyLoadSelector = ".transparent-lazy-load";

    //Initialize image lazy loading in page
    $(document).ready(function () {
        var lazyImages = CINEPLEX.ImageLazyLoader.GetLazyElements();

        //Check browser support
        if ("IntersectionObserver" in window) {
            //Create lazy load observer
            var lazyImageObserver = new IntersectionObserver(function (entries, observer) {
                entries.forEach(function (entry) {
                    if (entry.isIntersecting) {
                        var lazyImage = entry.target;
                        hideLoader(lazyImage);
                        lazyImage.classList.remove(defaultImageSrc);
                        lazyImage.classList.remove(defaultTransparentImageSrc);
                        if (lazyImage.dataset.srcset) {
                            lazyImage.srcset = lazyImage.dataset.srcset;
                        }
                        if (lazyImage.nodeName === "IMG") {
                            lazyImage.src = lazyImage.dataset.src;
                        }
                        lazyImageObserver.unobserve(lazyImage);
                    }
                });
            });

            CINEPLEX.ImageLazyLoader.ConfigureImagesForLazyLoad = function (images) {
                //Add lazy load observer to images
                images.forEach(function (lazyImage) {
                    lazyImageObserver.observe(lazyImage);
                    var imgSrc = $(lazyImage).parents(transparentLazyLoadSelector).length ?
                        defaultTransparentImageSrc :
                        defaultImageSrc;

                    //Set default blank image
                    lazyImage.classList.add(imgSrc);
                });
            }

            CINEPLEX.ImageLazyLoader.ConfigureImagesForLazyLoad(lazyImages);
        } else {
            //Browser doesn't support observer, fallback to more reliable method
            CINEPLEX.ImageLazyLoader.Images = [];
            CINEPLEX.ImageLazyLoader.ConfigureImagesForLazyLoad = function (images) {
                //Set default blank image
                lazyImages.forEach(function (lazyImage) {
                    var imgSrc = $(lazyImage).parents(transparentLazyLoadSelector).length ?
                        defaultTransparentImageSrc :
                        defaultImageSrc;

                    //Set default blank image
                    lazyImage.classList.add(imgSrc);
                });

                CINEPLEX.ImageLazyLoader.Images = CINEPLEX.ImageLazyLoader.Images.concat(images);
                lazyLoad();
            }

            var active = false;
            var lazyLoad = function () {
                //ensure lazy load calls don't overlap on scroll/resize
                if (active === false) {
                    active = true;
                    //add a slight delay so scrolling is a bit smoother
                    setTimeout(function () {
                        CINEPLEX.ImageLazyLoader.Images.forEach(function (lazyImage) {
                            var imageBounds = lazyImage.getBoundingClientRect();
                            //check if image is within the page
                            //this won't check if the image is hidden like IntersectionObserver would, but it's a fallback so it'll have to do
                            if ((imageBounds.top <= window.innerHeight && imageBounds.bottom >= 0) && getComputedStyle(lazyImage).display !== "none") {
                                hideLoader(lazyImage);
                                lazyImage.classList.remove(defaultImageSrc);
                                lazyImage.classList.remove(defaultTransparentImageSrc);
                                if (lazyImage.dataset.srcset) {
                                    lazyImage.srcset = lazyImage.dataset.srcset;
                                }
                                if (lazyImage.nodeName === "IMG") {
                                    lazyImage.src = lazyImage.dataset.src;
                                }

                                CINEPLEX.ImageLazyLoader.Images = CINEPLEX.ImageLazyLoader.Images.filter(function (image) {
                                    return image !== lazyImage;
                                });
                            }
                        });
                        //allow lazy load listener to fire again
                        active = false;
                    }, 200);
                }
            };

            //Add listener to events that might bring images into the window
            document.addEventListener("scroll", lazyLoad);
            window.addEventListener("resize", lazyLoad);
            window.addEventListener("orientationchange", lazyLoad);
            //Initial load of images
            CINEPLEX.ImageLazyLoader.ConfigureImagesForLazyLoad(lazyImages);
            lazyLoad();
        }

        //hide loading spinner (Latest News Section on Homepage)
        hideLoader = function (elem) {
            var block = $(elem).parents("a");
            var loader = block.prev(".news-img-load");
            loader.hide();
        }
    });
})();