var CINEPLEX = window.CINEPLEX || {};

CINEPLEX.movioServices = (function () {

    var self = this;

    self.trackMemberLogin = trackMemberLogin;

    self.trackMovieDetail = trackMovieDetail;

    self.trackMovieRating = trackMovieRating;

    self.trackMovieTrailer = trackMovieTrailer;

    return self;

    function trackMemberLogin(successCallback, errorCallback) {
        var requestObject = {
            MovioAction: 1
        };
        validateMovioRequestAndSend(requestObject, successCallback, errorCallback);
    }

    function trackMovieDetail(filmId, successCallback, errorCallback) {
        var requestObject = {
            MovioAction: 2,
            FilmId: filmId
        };
        validateMovioRequestAndSend(requestObject, successCallback, errorCallback);
    }

    function trackMovieRating(filmId, memberRating, successCallback, errorCallback) {
        var requestObject = {
            MovioAction: 4,
            FilmId: filmId,
            MemberRating: memberRating
        };
        validateMovioRequestAndSend(requestObject, successCallback, errorCallback);
    }

    function trackMovieTrailer(filmId, successCallback, errorCallback) {
        var requestObject = {
            MovioAction: 5,
            FilmId: filmId
        };
        validateMovioRequestAndSend(requestObject, successCallback, errorCallback);
    }

    function validateMovioRequestAndSend(requestObject, successCallback, errorCallback) {
        if (Cineplex && Cineplex.Connect && Cineplex.Connect.IsUserLoggedIn()) {
            var userSessionTokenCookieResult = CINEPLEX.generalFunctions.getCookieValue("CCToken");
            var connectUserInfo = Cineplex.Connect.GetUserStatus();
            if (userSessionTokenCookieResult.found && connectUserInfo.UserProfileGuid) {
                requestObject.UserSessionToken = userSessionTokenCookieResult.value;
                requestObject.UserProfileGuid = connectUserInfo.UserProfileGuid;
                CINEPLEX.generalFunctions.jsonPostRequest("/Movio/SendActionToMovio", requestObject, successCallback, errorCallback);
            }
            else {
                if (errorCallback) {
                    errorCallback(error);
                }
            }
        }
    }

})();