var CINEPLEX = window.CINEPLEX || {};

CINEPLEX.generalFunctions = (function () {
	var self = this;

	self.jsonPostRequest = jsonPostRequest;

	self.getCookieValue = getCookieValue;

	function jsonPostRequest(url, requestObject, successCallback, errorCallback) {
		var xhr = new XMLHttpRequest();
		xhr.open("POST", url, true);
		xhr.setRequestHeader("Content-Type", "application/json");
		xhr.onreadystatechange = function () {
		    if (xhr.readyState === 4 && xhr.status === 200 && successCallback) {
				successCallback(http.responseText);
			}
		    else if (xhr.readyState === 4 && xhr.status !== 200 && errorCallback) {
				errorCallback(http.responseText);
			}
		}
		xhr.send(JSON.stringify(requestObject));
	}

	function getCookieValue(cookieName) {
		var result = {
			found: false,
			value: ""
		};
		var name = cookieName + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var cookieArray = decodedCookie.split(';');
		for (var i = 0; i < cookieArray.length; i++) {
			var cookie = cookieArray[i];
			while (cookie.charAt(0) == ' ') {
				cookie = cookie.substring(1);
			}
			if (cookie.indexOf(name) == 0) {
				result.found = true;
				result.value = cookie.substring(name.length, cookie.length);
				return result;
			}
		}
		return result;
	}

	return self;

})();