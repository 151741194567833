window.CINEPLEX = window.CINEPLEX || {};

//*** LocationData added by miklos dec 4 2013
//we use this to store user location data
window.CINEPLEX.LocationData = {};

//make sure this matches backend location cookie logic value
window.CINEPLEX.LocationData.currCookieVersion = "cookie-v4.5";

//city is not yet converted to URL format
window.CINEPLEX.LocationData.setLocationDataWithCityProv = function (city, provCode, latitude, longitude) {
    //if not empty
    if (city && provCode) {

        
        //city and province code display name
        var cityProv = city + " - " + provCode;


        //city converted to url format
        var cityUrl = window.CINEPLEX.LocationData.convertToUrl(city);

        //set location cookie
        window.CINEPLEX.LocationData.setLocationData(cityUrl, provCode, cityProv, latitude, longitude);
    }
};

//city is url format of city name. cityProv is location display name.
window.CINEPLEX.LocationData.setLocationData = function (city, provCode, cityProv, latitude, longitude) {
    //set empty string if null or undefined
    if (!city) city = "";
    if (!provCode) provCode = "";
    if (!cityProv) cityProv = "";
    if (!latitude) latitude = "";
    if (!longitude) longitude = "";

    //ensure uppercase where required
    city = city.toUpperCase();
    provCode = provCode.toUpperCase();
    cityProv = cityProv.toUpperCase();

    if (city == 'FIFTH-AVENUE-CINE') {
        city = 'VANCOUVER';
        provCode = 'BC';
        cityProv = 'VANCOUVER - BC';
    }
   
   
    if (cityProv) cityProv = decodeURIComponent(cityProv);
    //set data object with corrected parameters where applicable
    if (city) window.CINEPLEX.LocationData.city = city;
    if (provCode) window.CINEPLEX.LocationData.provCode = provCode;
    if (latitude) window.CINEPLEX.LocationData.latitude = latitude;
    if (longitude) window.CINEPLEX.LocationData.longitude = longitude;
    if (cityProv) window.CINEPLEX.LocationData.displayName = cityProv;

    //expire cookie in a year
    var inOneYear = new Date();
    inOneYear.setFullYear(inOneYear.getFullYear() + 1);

    
    //set cookie value
    var locArray = [cityProv, latitude, longitude, provCode, city, window.CINEPLEX.LocationData.currCookieVersion];
    $.cookie("Cineplex_Location", locArray.join("|"), { domain: '.cineplex.com', expires: inOneYear, path: '/' });

    //something location related was set
    $(document).trigger("locationDetected", window.CINEPLEX.LocationData);
};

window.CINEPLEX.LocationData.getLocationDataFromCookie = function () {
    var cookie = $.cookie("Cineplex_Location");

    //if cookie exists
    if (cookie) {
        var locArray = cookie.split("|");

        //delete invalid cookie
        if (locArray.length < 6 || locArray[5] != window.CINEPLEX.LocationData.currCookieVersion) {
            //remove previous cookie
            window.CINEPLEX.LocationData.removeCookie();
            return;
        }

        



        //split the cookie and set the variables where applicable
        if (locArray[4]) {
            if (locArray[4] == 'FIFTH-AVENUE-CINE') {
                window.CINEPLEX.LocationData.city = 'VANCOUVER';
                window.CINEPLEX.LocationData.provCode = 'BC';
                window.CINEPLEX.LocationData.displayName = 'VANCOUVER - BC';
            } else {
                window.CINEPLEX.LocationData.city = locArray[4];
                window.CINEPLEX.LocationData.provCode = locArray[3];
                window.CINEPLEX.LocationData.displayName = decodeURIComponent(locArray[0]);
            }

            
        }

        

        
            
        if (locArray[1]) window.CINEPLEX.LocationData.latitude = locArray[1];
        if (locArray[2]) window.CINEPLEX.LocationData.longitude = locArray[2];
        
          
    }
};

//this may be overboard but just exhausting all possibilities
window.CINEPLEX.LocationData.removeCookie = function () {
    var possibleNames = ["Location_Cookie", "Cineplex_Location"];

    //NOTE: when deleting cookie, domain and path must match.

    //for each possible cookie name
    for (var i = 0; i < possibleNames.length; i++) {
        //default options
        $.removeCookie(possibleNames[i]);

        //specific path option set
        $.removeCookie(possibleNames[i], { domain: 'cineplex.com', path: '/' });
        $.removeCookie(possibleNames[i], { domain: '.cineplex.com', path: '/' });
        $.removeCookie(possibleNames[i], { domain: 'www.cineplex.com', path: '/' });
        $.removeCookie(possibleNames[i], { domain: 'uat.cineplex.com', path: '/' });
        $.removeCookie(possibleNames[i], { domain: 'prestage.cineplex.com', path: '/' });
        $.removeCookie(possibleNames[i], { domain: 'redesign.cineplex.com', path: '/' });
        $.removeCookie(possibleNames[i], { path: '/' });

        //specific domain set
        $.removeCookie(possibleNames[i], { domain: 'cineplex.com' });
        $.removeCookie(possibleNames[i], { domain: '.cineplex.com' });
        $.removeCookie(possibleNames[i], { domain: 'www.cineplex.com' });
        $.removeCookie(possibleNames[i], { domain: 'uat.cineplex.com' });
        $.removeCookie(possibleNames[i], { domain: 'prestage.cineplex.com' });
        $.removeCookie(possibleNames[i], { domain: 'redesign.cineplex.com' });
    }
}

window.CINEPLEX.LocationData.isLocationSet = function () {
    var obj = window.CINEPLEX.LocationData;

    //try and get location data from cookie
    obj.getLocationDataFromCookie();

    //if city is set we assume province is set
    //if latitude set, we assume longitude is set
    if (obj.city || obj.latitude) return true;
    return false;
};

window.CINEPLEX.LocationData.getLocationFromIp = function () {
    var url = "/Utilities/GetLocationByIp";

    //add screen blocker
    window.CINEPLEX.AddAjaxOverlay();

    $.get(url, function (data) {
        //if a non-empty response received
        if (data) {
            //split the response
            var split = data.split(",");

            //get various values
            var provCode = split[1];
            var city = split[2];

            //parse latitude and longitude
            var latitude = parseFloat(split[3]);
            var longitude = parseFloat(split[4]);
            
            //set location cookie and location data
            window.CINEPLEX.LocationData.setLocationDataWithCityProv(city, provCode, latitude, longitude);
        }
        else {
            //location not found
            alert('Sorry, could not find location.');
        }

        //remove screen blocker and fire event
        CINEPLEX.RemoveAjaxOverlay();
        $(document).trigger("geoLocateByIpAttemptComplete");
    });
};

window.CINEPLEX.lookupGeoPointAddress = function (latitude, longitude) {
    //if values passed in
    if (latitude && longitude) {
        var url = "http://dev.virtualearth.net/REST/v1/Locations/" + latitude + "," + longitude;

        //make bing ajax call
        $.ajax({
            url: url,
            dataType: "jsonp",
            data: {
                //key: 'AkfjoCEARJhaEGrlLIjJAIqhIzbfwmhqryey55e-TKQmgXoMAu47coqwZmU1yosk',
                key: 'AhAp3YpHuwgyFWyHHIrCvoGel3DXTKVneVNMHibEsAon4XlunA2kBRbwMGnA3Svh',
                maxRes: 1
            },
            jsonp: "jsonp",
            success: function (data) {
                //if results
                if (data.resourceSets.length > 0) {
                    //get address from response
                    var address = data.resourceSets[0].resources[0].address;

                    //get attributes
                    var city = address.locality;
                    var provCode = address.adminDistrict;

                    //set location cookie and location data
                    window.CINEPLEX.LocationData.setLocationDataWithCityProv(city, provCode, latitude, longitude);
                }

                //trigger event whether successful or not
                $(document).trigger("lookupGeoPointAddressAttemptComplete");
            },
            error: function () {
                //trigger event anyway
                $(document).trigger("lookupGeoPointAddressAttemptComplete");
            }
        });
    }
};

//attempts to emulate functionality of cdatabase ConvertToFilmUrl
window.CINEPLEX.LocationData.convertToUrl = function (txt) {
    //convert french accents to english equivalents
    var accentDictionary = { 'Ë': 'E', 'À': 'A', 'Ì': 'I', 'Â': 'A', 'Í': 'I', 'Ã': 'A', 'Î': 'I', 'Ä': 'A', 'Ï': 'I', 'Ç': 'C', 'Ò': 'O', 'È': 'E', 'Ó': 'O', 'É': 'E', 'Ô': 'O', 'Ê': 'E', 'Õ': 'O', 'Ö': 'O', 'ê': 'e', 'Ù': 'U', 'ë': 'e', 'Ú': 'U', 'î': 'i', 'Û': 'U', 'ï': 'i', 'Ü': 'U', 'ô': 'o', 'Ý': 'Y', 'õ': 'o', 'â': 'a', 'û': 'u', 'ã': 'a', 'ÿ': 'y', 'ç': 'c' };
    for (var key in accentDictionary) {
        txt = txt.split(key).join(accentDictionary[key]);
    }

    //convert to lower case
    txt = txt.toLowerCase();

    //strip all non-alpha numeric characters
    txt = txt.split(/[^a-z0-9_ ]/).join('');

    //convert multiple spaces to single dash.
    txt = txt.split(/\s+/).join('-');

    return txt;
}

//event listener listening for browser location detection
$(document).on('geolocationComplete', function (event, latitude, longitude) {
    //set location cookie and location data
    window.CINEPLEX.LocationData.setLocationData(null, null, null, latitude, longitude);

    //see if we can get province and city for address
    window.CINEPLEX.lookupGeoPointAddress(latitude, longitude);
});

//event listener for when city selected from dropdown
$(document).on("citySelected", function (event, cityUrl, provCode, cityProv) {
    window.CINEPLEX.LocationData.setLocationData(cityUrl, provCode, cityProv, null, null);
});
