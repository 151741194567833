window.CINEPLEX = window.CINEPLEX || {};

(function () {
    $(document).on('opened', '[data-remodal-id=theatre-map-modal]', function () {
        if (typeof google === 'object' && typeof google.maps === 'object') {
            initGoogleMaps();
        } else {
            $.getScript("https://maps.googleapis.com/maps/api/js?v=3.exp&sensor=false&key=AIzaSyA2qRw8cegU_I-_ytwyZtIW6eFZ9Tr2Fso&language=" + (typeof window.CINEPLEX.currentLang !== 'undefined' ? window.CINEPLEX.currentLang.substring(0, 2) : "en") + "&callback=initGoogleMaps");
        }
    });

    $(document).on('close', '[data-remodal-id=seatmap]', function () {
        $('.seatMapWindowUrl').attr("src", "");
    });

    //global functions  used for seat map.
    buyTicketsNow = function (locationId, sessionId, areaCategoryCode) {
        $.remodal.lookup[$('[data-remodal-id=seatmap]').data('remodal')].close();
        var showtimeIdLinkId = "l_" + locationId + "_s_" + sessionId + "_c_" + areaCategoryCode;
        if (showtimeIdLinkId !== null && showtimeIdLinkId !== "") {
            var oldConnectCloseFunction;
            if (!Cineplex.Connect.IsUserLoggedIn()) {
                oldConnectCloseFunction = Cineplex.Connect.Settings.Close;
                Cineplex.Connect.Settings.Close = function () {
                    oldConnectCloseFunction();
                    if (Cineplex.Connect.IsUserLoggedIn()) {
                        window.location = $('#' + showtimeIdLinkId).attr('data-ng-href');
                    }
                    Cineplex.Connect.Settings.Close = oldConnectCloseFunction;
                };
                Cineplex.Connect.OpenConnectPage(1);
            } else
                window.location = $('#' + showtimeIdLinkId).attr('data-ng-href');
            
        }
    }

    noTicketsAvailable = function () {
        $.remodal.lookup[$('[data-remodal-id=showtime-na-modal]').data('remodal')].open();
    }

    window.CINEPLEX.ShowtimesV2 = new function () {
        var self = this;

        //vars later to be filled with jquery elements
        self.$advancedSearchGrid = "";

        //jQuery Selector Arrays
        self.jQuerySelectors = {
            AdvancedSearchLink: '#advancedSearch',
            //new Showtime Search
            FindTheatresLink: '#FindTheatres',
            CompareTheatresLink: '#CompareTheatres',
            TheatreMapModalLink: '.theatre-map-modal',
            EmailClickLink: '.email-link-click',
            PrintClickLink: '.print-link-click',
            TheatreInfoAndPricesClickLink: '.theatre-info-link-click',
            MovieDetailsClickLink: '.movie-details-link-click',
            TrailerPlayerLink: '.trailer-player-click',
            Modals: { //used for hash string also
                TheatreMap: 'theatre-map-modal',
                TrailerPlayer: 'trailer-player-modal'
            }
        }
        //ShowtimesV2 Init
        self.Init = function () {
            //get all jquery elements from the dom
            getJQueryElements();

            //all event related things
            addEventHandlers();

            //call events based on conditions (manual triggers)
            callEventsManually();

        };

        var getJQueryElements = function () {
            self.$advancedSearchGrid = $('#advancedSearchGrid').find('.grid__item'); //done this way for performance
            self.$FindTheatres = $('.page-content').find('#FindTheatresDiv'); //done this way for performance
            self.$CompareTheatres = $('.page-content').find('#CompareTheatresDiv'); //done this way for performance
        }

        var addEventHandlers = function () {

            //event.preventDefault does not work in some IE versions
            var preventTheDefault = function (event) {
                if (event.preventDefault) event.preventDefault();
                else event.returnValue = false;
            }

            //Advanced search link is clicked on showtimes search bar
            $(document).on('click', self.jQuerySelectors.AdvancedSearchLink, function (event) {
                preventTheDefault(event);
                $(this).find('i').toggleClass('icon-arrow-down').toggleClass('icon-arrow-up');
                $(this).attr('aria-expanded', true);
                //toggle the arrow
                if (self.$advancedSearchGrid.is(':visible')) {
                    self.$advancedSearchGrid.slideUp(200);
                    $(this).attr('aria-expanded', false);
                    $(this).parent().attr('class', 'advancedSearchWrapper');
                } else {
                    self.$advancedSearchGrid.slideDown(200);
                    $(this).parent().attr('class', 'advancedSearchWrapper expanded');
                }
            });

            //Find Button link is clicked on showtimes search bar
            $(document).on('click', self.jQuerySelectors.FindTheatresLink, function (event) {
                preventTheDefault(event);
                //toggle the arrow
                if (self.$CompareTheatres.is(':visible')) {
                    self.$CompareTheatres.slideUp(200);
                    self.$FindTheatres.slideDown(200);
                } else {
                    self.$CompareTheatres.slideUp(200);
                    self.$FindTheatres.slideDown(200);
                }

            });

            //Advanced search link is clicked on showtimes search bar
            $(document).on('click', self.jQuerySelectors.CompareTheatresLink, function (event) {
                preventTheDefault(event);
                //toggle the arrow
                if (self.$FindTheatres.is(':visible')) {
                    self.$FindTheatres.slideUp(200);
                    self.$CompareTheatres.slideDown(200);
                } else {
                    self.$FindTheatres.slideUp(200);
                    self.$CompareTheatres.slideDown(200);
                }
            });

            $(document).on('click', self.jQuerySelectors.TheatreMapModalLink, function (event) {
                preventTheDefault(event);
                CINEPLEX.AddAjaxOverlay();
                var $_self = $(this);
                var locationId = $_self.data('loc');
                var trackingType = $_self.data('linktype');
                $.ajax({
                    url: $_self.data('modal-url'),
                    cache: false,
                    success: function (data) {
                        var $modal = $('[data-remodal-id=' + self.jQuerySelectors.Modals.TheatreMap + ']');
                        var $content = $modal.find('.modal-content');
                        $content.html(data);
                        $.remodal.lookup[$modal.data('remodal')].open();
                        CINEPLEX.RemoveAjaxOverlay();
                    }
                });
            });

            $(document).on('click', self.jQuerySelectors.EmailClickLink, function (event) {
                preventTheDefault(event);
                var $_self = $(this),
                    theatreUrl = $_self.data('theatre-url'),
                    showtimeLink = self.GetTheatreShowtimeUrlForEmail(theatreUrl),
                    mailTo = $_self.data('email-link').replace("%7B0%7D", "https://" + showtimeLink); // %7B0%7D = {0}
                location.href = mailTo;
            });

            $(document).on('click', self.jQuerySelectors.TrailerPlayerLink, function (event) {
                preventTheDefault(event);
                CINEPLEX.AddAjaxOverlay();
                var $_self = $(this);
                var videoId = $_self.data('videoid');
                $.ajax({
                    url: '/Utilities/GetTrailer/',
                    data: { videoId: videoId, autoPlay: true },
                    cache: false,
                    success: function (data) {
                        var $modal = $('[data-remodal-id=' + self.jQuerySelectors.Modals.TrailerPlayer + ']');
                        $modal.find('.modal-content').html(data);
                        $.remodal.lookup[$modal.data('remodal')].open();
                        CINEPLEX.RemoveAjaxOverlay();
                    }
                });
            });

            $(document).on('close', '[data-remodal-id=' + self.jQuerySelectors.Modals.TrailerPlayer + ']', function () {
                var $_self = $(this);
                if (typeof BC === "object" && typeof BC.$player === "object") {
                    BC.$player.brightcoveVideo("pause");
                }
                $_self.find('.modal-content').empty();
            });
        }

        self.GetTheatreShowtimeUrlForEmail = function (theatreUrl) {
            return encodeURIComponent(window.location.host + "/" + document.location.pathname.split('/')[1] + "/" + document.location.pathname.split('/')[2] + "/" + theatreUrl + removeParameter(document.location.search, 'page'));
        }

        function removeParameter(url, parameter) {
            //Get Query String from url
            fullQString = url.substring(1);

            paramCount = 0;
            queryStringComplete = "?";

            if (fullQString.length > 0) {
                //Split Query String into separate parameters
                paramArray = fullQString.split("&");

                //Loop through params, check if parameter exists.  
                for (i = 0; i < paramArray.length; i++) {
                    currentParameter = paramArray[i].split("=");
                    if (currentParameter[0].toLowerCase() == parameter.toLowerCase()) //Parameter already exists in current url
                    {
                        //don't include existing (will be appended to end of url)
                    } else //Existing unrelated parameter
                    {
                        if (paramCount > 0)
                            queryStringComplete = queryStringComplete + "&";

                        queryStringComplete = queryStringComplete + paramArray[i];
                        paramCount++;
                    }
                }
            }

            return queryStringComplete;
        }

        var callEventsManually = function () {

            if (window.CINEPLEX.showtimes && !window.CINEPLEX.showtimes.IsBasicSearch) {
                $(self.jQuerySelectors.AdvancedSearchLink).trigger('click');
                $(self.jQuerySelectors.FindTheatresLink).trigger('click');
                $(self.jQuerySelectors.CompareTheatresLink).trigger('click');
            }
        }
    }
})();

