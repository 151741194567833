window.CINEPLEX = window.CINEPLEX || {};

//minimum string length to fetch autocomplete data from server
window.CINEPLEX.autoCompleteMinChars = 3;

//set this when favs are required, to prevent unnecessary loading
window.CINEPLEX.LoadFavourites = false;

jQuery(function ($) {
    // requires jQuery 1.8
    (function (app, undefined) {

        var cineplex = {

            init: function () {

                app.globalSetup();

                app.geoLocation();
            },

            /**
             * Global variables and general site setup
             */
            globalSetup: function () {
                cineplex.globalOptions = {
                    html: $('html'),
                    bod: $('body')
                }
                cineplex.globalOptions.isGeolocation = ('geolocation' in navigator) ? true : false;
                cineplex.globalOptions.geoLat = null;
                cineplex.globalOptions.geoLong = null;
            },

            geoLocation: function () {
                if (!cineplex.globalOptions.isGeolocation) return;

                var options = {
                    enableHighAccuracy: false,
                    timeout: 1000000,
                    maximumAge: 0
                };

                function success(pos) {
                    var crd = pos.coords;

                    // set to global variable
                    cineplex.globalOptions.geoLat = crd.latitude;
                    cineplex.globalOptions.geoLong = crd.longitude;

                    // Send geolocation to Cineplex's function
                    $(document).trigger('geolocationComplete', [cineplex.globalOptions.geoLat, cineplex.globalOptions.geoLong]);
                };

                function error(err) {
                    // could not get user's location
                    console.warn('Unable to retrieve geolocation');
                };

                //geolocate only if the page uses geolocation and there is no loc cookie
                if (window.CINEPLEX.LocationData.isLocationSet() == false) {
                    navigator.geolocation.getCurrentPosition(success, error, options);
                }
            }

        };

        $.extend(app, cineplex);
    }(window.CINEPLEX = window.CINEPLEX || {}));

    CINEPLEX.init();
});